export const TEST_WEB_URL = "https://autobot-ui-test.brainchain.cloud";
export const PROD_WEB_URL = "https://autobot-ui.brainchain.cloud";
export const DEV_WEB_URL = "http://localhost:3000";

export const PROD_API_BASE_URL = "https://api.brainchain.cloud";
export const TEST_API_BASE_URL = "https://api-test.brainchain.cloud";

export const API_BASE_URL =
  window.location.origin === PROD_WEB_URL
    ? PROD_API_BASE_URL
    : TEST_API_BASE_URL;

export const PROD_AUTOBOT_API_BASE_URL = "https://autobot.brainchain.cloud";
export const TEST_AUTOBOT_API_BASE_URL =
  "https://autobot-test.brainchain.cloud";

export const AUTOBOT_API_BASE_URL =
  window.location.origin === PROD_WEB_URL
    ? PROD_AUTOBOT_API_BASE_URL
    : TEST_AUTOBOT_API_BASE_URL;

// "https:/document-ingestion-test.brainchain.cloud/";

export const FILE_UPLOAD_URL = `https://document-ingestion.brainchain.cloud/api/v1/knowledge/files/upload`;
export const GET_AGENTS_URL = `${AUTOBOT_API_BASE_URL}/api/v1/agents`;
export const CONVERSATION_LIST_URL = `${AUTOBOT_API_BASE_URL}/api/v1/conversations/list`;
export const CREATE_CONVERSATION_URL = `${AUTOBOT_API_BASE_URL}/api/v1/conversations/create`;
export const GENERATE_CONVERSATION_URL = `${AUTOBOT_API_BASE_URL}/api/v1/conversations/generate`;
export const ADD_MESSAGE_URL = `${AUTOBOT_API_BASE_URL}/api/v1/conversations/add_message`;
export const LOAD_MESSAGES_URL = `${AUTOBOT_API_BASE_URL}/api/v1/conversations/load`;
export const GET_AGENT_STATE_URL = `${AUTOBOT_API_BASE_URL}/api/v1/agent/state`;

// Temporary endpoint while we wait for the Engine API to be completed
// https://completions-test.brainchain.cloud/api/v1/generate
export const COMPLETION_URL =
  "https://completions-test.brainchain.cloud/api/v1/generate";
