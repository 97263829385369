import React, { useState, useEffect } from "react";
import {
  Send,
  Paperclip,
  FileText,
  PlusCircle,
  ChevronLeft,
  ChevronRight,
} from "lucide-react";
import { mockChats } from "./mockConversation";
import { COMPLETION_URL } from "../../api/constants";

interface Message {
  id: number;
  content: string | React.ReactNode;
  sender: "user" | "bot";
  timestamp: Date;
  type: "text" | "file" | "image" | "chart";
}

interface Chat {
  id: number;
  title: string;
  messages: Message[];
}

const ChatInterface: React.FC = () => {
  const [chats, setChats] = useState<Chat[]>([]);
  // @ts-ignore
  const [currentChat, setCurrentChat] = useState<Chat>([]);
  const [messages, setMessages] = useState<Message[]>(currentChat.messages);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    const messageList = document.getElementById("message-list");
    if (messageList) {
      messageList.scrollTop = messageList.scrollHeight;
    }
  }, [messages]);

  const generateStreamResponse = async (prompt: any) => {
    await fetch(COMPLETION_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/event-stream",
      },
      body: JSON.stringify({
        // prompts: [prompt],
        messages: [
          {
            role: "user",
            content: "Hey! how are you?",
          },
        ],
        provider: "anthropic",
        model: "claude-3-opus-20240229",
        temperature: 0.7,
        max_tokens: 1024,
        top_p: 1,
        stream: true,
      }),
    }).then((response) => response.body);
  };

  const handleSend = () => {
    if (input.trim()) {
      generateStreamResponse(input.trim());
      // const newMessage: Message = {
      //   id: Date.now(),
      //   content: input.trim(),
      //   sender: "user",
      //   timestamp: new Date(),
      //   type: "text",
      // };
      // setMessages([...messages, newMessage]);
      // setInput("");
      // setIsLoading(true);
      // setTimeout(() => {
      //   const botResponse: Message = {
      //     id: Date.now(),
      //     content:
      //       "I'm processing your request. This is a placeholder response.",
      //     sender: "bot",
      //     timestamp: new Date(),
      //     type: "text",
      //   };
      //   setMessages((prevMessages) => [...prevMessages, botResponse]);
      //   setIsLoading(false);
      // }, 1500);
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const uploadMessage: Message = {
        id: Date.now(),
        content: (
          <div className="flex items-center">
            <FileText className="mr-2" />
            {file.name}
          </div>
        ),
        sender: "user",
        timestamp: new Date(),
        type: "file",
      };
      setMessages([...messages, uploadMessage]);
    }
  };

  const handleChatSelect = (chat: Chat) => {
    setCurrentChat(chat);
    setMessages(chat.messages);
    setIsSidebarOpen(false);
  };

  const handleNewChat = () => {
    const newChat: Chat = {
      id: Date.now(),
      title: "New Chat",
      messages: [],
    };
    setChats([newChat, ...chats]);
    setCurrentChat(newChat);
    setMessages([]);
    setIsSidebarOpen(false);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex h-[calc(100vh-8rem)] relative">
      {/* Sidebar for previous chats */}
      <div
        className={`absolute md:relative w-3/4 md:w-1/4 bg-gray-100 p-4 rounded-lg shadow-lg h-full transition-transform duration-300 ease-in-out ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } md:translate-x-0 z-10`}
      >
        <div className="mb-4 flex justify-between items-center">
          <button
            onClick={handleNewChat}
            className="flex items-center bg-[#004DB5] text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none"
          >
            <PlusCircle size={20} className="mr-2" />
            New Chat
          </button>
          <button onClick={toggleSidebar} className="md:hidden text-[#004DB5]">
            <ChevronLeft size={24} />
          </button>
        </div>
        <div
          className="space-y-4 overflow-y-auto"
          style={{ maxHeight: "calc(100% - 4rem)" }}
        >
          {/* {chats.map((chat) => (
            <div
              key={chat.id}
              onClick={() => handleChatSelect(chat)}
              className={`p-3 rounded-lg cursor-pointer ${
                chat.id === currentChat.id
                  ? "bg-[#004DB5] text-white"
                  : "bg-white text-black"
              }`}
            >
              <h3 className="font-bold">{chat.title}</h3>
              <p className="text-sm">
                {chat.messages[chat.messages.length - 1]?.content
                  ?.toString()
                  .slice(0, 20)}
                ...
              </p>
            </div>
          ))} */}
        </div>
      </div>

      {/* Main chat interface */}
      <div className="w-full md:w-3/4 bg-white rounded-lg shadow-lg p-4 flex flex-col">
        {/* Mobile sidebar toggle button */}
        <button
          onClick={toggleSidebar}
          className="md:hidden text-[#004DB5] mb-4 self-start"
        >
          <ChevronRight size={24} />
        </button>

        <div
          className="flex-1 overflow-y-auto mb-4 space-y-4"
          id="message-list"
        >
          {/* {messages.map((message) => (
            <div
              key={message.id}
              className={`flex ${
                message.sender === "user" ? "justify-end" : "justify-start"
              }`}
            >
              <div
                className={`max-w-[70%] p-3 rounded-lg ${
                  message.sender === "user"
                    ? "bg-[#D8E9FF] text-[#000000]"
                    : "bg-[#F5F5F5] text-[#000000]"
                }`}
              >
                {message.type === "text" && <p>{message.content}</p>}
                {message.type === "file" && message.content}
                {message.type === "image" && (
                  <div className="w-full h-40 bg-gray-200 flex items-center justify-center">
                    <Image size={24} />
                  </div>
                )}
                {message.type === "chart" && message.content}
                <span className="text-xs" style={{ color: "#7D7D7D" }}>
                  {message.timestamp.toLocaleTimeString()}
                </span>
              </div>
            </div>
          ))} */}
          {isLoading && (
            <div className="flex justify-start">
              <div className="bg-[#F5F5F5] text-[#000000] p-3 rounded-lg">
                <p>Typing...</p>
              </div>
            </div>
          )}
        </div>
        <div className="flex items-center space-x-2">
          <label htmlFor="file-upload" className="cursor-pointer">
            <Paperclip className="text-[#B2B2B2] hover:text-[#004DB5]" />
            <input
              id="file-upload"
              type="file"
              className="hidden"
              onChange={handleFileUpload}
            />
          </label>
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={(e) => e.key === "Enter" && handleSend()}
            className="flex-1 border rounded-lg px-4 py-2 focus:outline-none focus:ring-2"
            placeholder="Type your message..."
            style={{ color: "#B2B2B2", borderColor: "#004DB5" }}
          />
          <button
            onClick={handleSend}
            className="bg-[#004DB5] text-white rounded-lg p-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <Send size={20} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatInterface;
